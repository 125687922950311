import React, { Component } from 'react';
import{Navbar,Nav} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "./../Media/FOGADE.png"
// import Facebook from "./../Media/facebook.svg"
import { Link} from "react-scroll";
import Social from "./Social"





class navbar extends Component{
  render(){
    
      return(
      <Navbar className="sticky-top" style={{backgroundColor:"#015180"}} expand="lg" >
  <img src={Logo}alt="Logo" className="Logo"/>
  <Navbar.Toggle  aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav  className="ml-auto">
      
      <Nav.Link href="/" className="text-white font-weight-bolder">Inicio</Nav.Link>
      <Nav.Link href="/Nosotros" className="text-white font-weight-bolder">Nosotros</Nav.Link>
      <Link className="text-white font-weight-bolder nav-link Ripple-parent active " to="Servicios" spy={true} smooth={true} offset={-70} duration= {500}>Servicios</Link>
      <Link className="text-white font-weight-bolder nav-link Ripple-parent active " to="Noticias" spy={true} smooth={true} offset={-70} duration= {500}>Noticias</Link>
      <Link className="text-white font-weight-bolder nav-link Ripple-parent active " to="Economia" spy={true} smooth={true} offset={-70} duration= {500}>Economia</Link>
          </Nav>
   
    <Social/>
    
  </Navbar.Collapse>
</Navbar>
    
      ); 
  }
  
}
export default navbar;



