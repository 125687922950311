import React, { Component } from 'react';
//import Header from "../01-Header"
import Navbar from "../02-Navbar"
import Carrousel from "../03-Carrousel"
import CardService from "../04-CardService"
import Carrousel1 from "../05-Carrousel"
import Social from "../07-Social"
import Economi from "../08-Economy&Finace"
import Gallery from "../09-Gallery"
import Enlaces from "../10-Links"
//import Footer from "../11-Footer"

import "../../index.css"
import { BrowserRouter as Router } from 'react-router-dom';


class Inicio extends Component{
    render(){
        return(<>          
            <Navbar/>
            <Router>
                <Carrousel/>
                <CardService/>
                <Carrousel1/>
                <Social/>
                <Economi/>
                <Gallery/>
                <Enlaces/>
                </Router>
           
            
            </>
        )
    }
    
}
export default Inicio;