import React, { Component } from "react";
import "../../index.css"
import axios from "axios";
import{Navbar,Nav,} from 'react-bootstrap';
import { MDBCard, MDBCardTitle, MDBBtn, MDBCardGroup, MDBCardImage, MDBCardText, MDBCardBody } from "mdbreact";

import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../Media/FOGADE.png"
import Social from "../Social"
const baseUrl = "https://catatumbo.fogade.gob.ve/";
class Convocatoria extends Component {

  state = {
    entradas: []
   }
  
  componentDidMount() {
  axios.get(baseUrl +"ConvocatoriasListGet")
     .then(res => {
        const entradas = res.data;
        this.setState({ entradas});
      })
  }
  
    render() {
      return (<div>
       <Navbar style={{backgroundColor:"#015180"}} expand="lg">
  <img src={Logo}alt="Logo" className="Logo"/>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="ml-auto">
      
      <Nav.Link href="/" className="text-white font-weight-bolder">Inicio</Nav.Link>
      <Nav.Link href="/Nosotros" className="text-white font-weight-bolder">Nosotros</Nav.Link>
    
     
          </Nav>
   
    <Social/>
    
  </Navbar.Collapse>
</Navbar>
      <div className="container" style={{height:"600px",overflow:"scrollY"}}>
     
      <MDBCardGroup>
      {this.state.entradas.map(entradas=>
      <MDBCard>
      
        <MDBCardBody>
          <MDBCardTitle style={{fontSize:"15px"}}>{entradas.Titulo}</MDBCardTitle>
         <p>Categoria: {entradas.Categoria}</p>
          <a class="btn btn-primary" href={entradas.Pdf} id="a" className="text-black">
          <MDBBtn color="primary" size="md">
          Ingrese aqui
          </MDBBtn>
          </a>
         
        </MDBCardBody>
      
      </MDBCard>
      )}

     
       
    </MDBCardGroup>





    
    </div> </div> )
    }

    }
    
    export default Convocatoria;
