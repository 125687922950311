import React, { Component } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { FaChevronLeft, FaChevronRight} from "react-icons/fa"
import axios from "axios"


const baseUrl = "https://catatumbo.fogade.gob.ve/";
 
class MyCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: true,
        entrada: []
    }
}
  componentDidMount() {
    axios.get(baseUrl+ 'GaleriasGet')
      .then(res => {
        const entrada = res.data;
        this.setState({ entrada,loading: false})
      })
    }
  
  render() {
    return (
      <div style={{marginBottom:"2rem"}} className="container">
        <div className="linea container">  <div className="text-center"><strong className="Textolinea" >Galeria </strong></div></div>
        <div style={{height:"250px"}}>
      <Carousel
      slidesPerPage={2}
      slidesPerScroll={1}
      animationSpeed={1000}
      autoPlay={4000}
      stopAutoPlayOnHover
     
      itemWidth={360}
      
      infinite
      arrowLeft={<FaChevronLeft />}
      arrowRight={<FaChevronRight/> }
      addArrowClickHandler
      centered
      
      
      
      >
        
        {this.state.entrada.map(entrada => (<a href={`/Galerias/${entrada.galeryid}`} style={{marginLeft:"15px"}}><img style={{borderRadius:"20px",maxHeight:"230px" }} className="img-fluid"  alt={entrada.id}src={entrada.galeria}/></a>))}
        
          
        
      </Carousel>
      </div>
      </div>  
      
    );
  }
}
export default MyCarousel;
