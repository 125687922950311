import React from "react";
import cx from "classnames";
import Collapse from "@kunukn/react-collapse";
import "../Styles/Nosotros.scss"
import{Navbar,Nav} from 'react-bootstrap';
import Logo from "../../Media/FOGADE.png"
import Social from "../Social"
import {  MDBView, MDBRow} from "mdbreact";
import { MDBCard} from 'mdbreact';
import uno from "../../Media/Valor1.png"
import uno1 from "../../Media/Valor2.png"
import uno2 from "../../Media/Valor3.png"
import uno3 from "../../Media/Valor4.png"
import Exelencia from '../../Media/valor22.png'
import Pueblo from '../../Media/valor11.png' 
import Vocacion from '../../Media/valor33.png'
import '../../index.css';
import Organigrama from "../../Media/Organigrama.png"

class App extends React.Component {
  state = {
    isOpen1: false,
    isOpen2: false,
    isOpen3: false,
    isOpen4: false,
    isOpen5: false,
    isOpen6: false,
    
  };

  render() {
    return (<>
        <Navbar style={{backgroundColor:"#015180"}} expand="lg">
  <img src={Logo}alt="Logo" className="Logo"/>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="ml-auto">
      
      <Nav.Link href="/" className="text-white font-weight-bolder">Inicio</Nav.Link>
      <Nav.Link href="/Nosotros" className="text-white font-weight-bolder">Nosotros</Nav.Link>
      <Nav.Link href="/Convocatoria" className="text-white font-weight-bolder">Convocatoria</Nav.Link>
          </Nav> 
   
     <Social/>
    
  </Navbar.Collapse>
</Navbar>
     
      <div className="app">
        <button
          className={cx("app__toggle", {
            "app__toggle--active": this.state.isOpen1
          })}
          onClick={() => this.toggle(1)}
        >
          <span className="app__toggle-text">¿Quienes Somos?</span>
          <div className="rotate90">
            
          </div>
        </button>
        <Collapse
          isOpen={this.state.isOpen1}
          className={
            "app__collapse app__collapse--gradient " +
            (this.state.isOpen1 ? "app__collapse--active" : "")
          }
        >
          <div className="app__content">
          El Fondo de Protección Social de los Depósitos Bancarios es un instituto autónomo con personalidad jurídica y patrimonio propio e independiente de los bienes de la República, adscrito al Ministerio del Poder Popular de Economía y Finanzas a los solos efectos de la tutela administrativa.
            
          </div>
        </Collapse>

        

        <button
          className={cx("app__toggle", {
            "app__toggle--active": this.state.isOpen2
          })}
          onClick={() => this.toggle(2)}
        >
          MISION
        </button>

        <Collapse
          isOpen={this.state.isOpen2}
          className={
            "app__collapse app__collapse--gradient " +
            (this.state.isOpen2 ? "app__collapse--active" : "")
          }
          transition="height 800ms cubic-bezier(0.4, 0, 0.2, 1)"
          aria-hidden={this.state.isOpen2 ? "false" : "true"}
          elementType="article"
          render={collapseState => (
            <React.Fragment>
              <div className="app__content">
              Garantizar los depósitos del público en las instituciones del sector bancario venezolano mediante la ejecución de acciones orientadas a dar respuesta efectiva y oportuna a los depositantes, administrando eficientemente los recursos y otros bienes de su propiedad, así como ejercer la función de liquidador de aquellas instituciones que así lo requieran y de sus personas jurídicas vinculadas, conforme al ordenamiento jurídico vigente, a fin de contribuir con la confianza y estabilidad del Sistema Financiero Nacional, demostrando un alto sentido de justicia y compromiso social, en beneficio del país y de su pueblo en concordancia con las políticas públicas contenidas en el Plan de Desarrollo Económico y Social de la Nación.

               
              </div>
            </React.Fragment>
          )}
        />

<button
          className={cx("app__toggle", {
            "app__toggle--active": this.state.isOpen3
          })}
          onClick={() => this.toggle(3)}
        >
          Vision
        </button>

        <Collapse
          isOpen={this.state.isOpen3}
          className={
            "app__collapse app__collapse--gradient " +
            (this.state.isOpen2 ? "app__collapse--active" : "")
          }
          transition="height 800ms cubic-bezier(0.4, 0, 0.2, 1)"
          aria-hidden={this.state.isOpen2 ? "false" : "true"}
          elementType="article"
          render={collapseState => (
            <React.Fragment>
              <div className="app__content">
              
	Ser un organismo de reconocido prestigio y credibilidad, lider latinoamericano como garante de los depósitos del público, comprometido con los usuarios del Sistema Financiero Nacional, transmitiéndoles tranquilidad y confianza, apoyado en tecnología de vanguardia, con bases fundamentales en la ética y el profesionalismo del personal que lo integra, formado con los más altos valores, con compromiso social y alto sentido de pertenecia, manteniendo una continua comunicación y colaboración con el resto de los integrantes de la red de Seguridad Bancaria, para garantizar una sólidad y éxitosa gestión, protegiendo de forma efectiva y oportuna los depósitos del público, orientada hacia los principios de justicia social, que contribuyan a alcanzar la mayor suma de felicidad posible

               
              </div>
            </React.Fragment>
          )}
        />
               <button
          className={cx("app__toggle", {
            "app__toggle--active": this.state.isOpen4
          })}
          onClick={() => this.toggle(4)}
        >
         POLÍTICAS INSTITUCIONALES
        </button>

        <Collapse
          isOpen={this.state.isOpen4}
          className={
            "app__collapse app__collapse--gradient " +
            (this.state.isOpen2 ? "app__collapse--active" : "")
          }
          transition="height 800ms cubic-bezier(0.4, 0, 0.2, 1)"
          aria-hidden={this.state.isOpen2 ? "false" : "true"}
          elementType="article"
          render={collapseState => (
            <React.Fragment>
              <div className="app__content">
             
              <ul class="list-group list-group-flush">
  <li class="list-group-item" style={{fontWeight:"normal"}}>1.  Monitoreo permanentemente de las instituciones del sector bancario.</li>
  <li class="list-group-item" style={{fontWeight:"normal"}}>2.	Agilizar los procesos de enajenación de activos y la cobranza de las carteras de crédito de la banca en liquidación. </li>
  <li class="list-group-item" style={{fontWeight:"normal"}}>3.	Administración eficaz de los recursos financieros, a través de operaciones de inversión en títulos seguros, rentables y de fácil realización.</li>
  <li class="list-group-item" style={{fontWeight:"normal"}}>4.	Estimular sinergia con otros organismos públicos que permita concretar acciones beneficiosas, en procesos relacionados con la adquisición de bienes y la prestación de servicios en general.</li>
  <li class="list-group-item" style={{fontWeight:"normal"}}>5.	Optimizar el control, protección y respaldo de la información.</li>
  <li class="list-group-item" style={{fontWeight:"normal"}}>6.	Implementación, mantenimiento y actualización de sistemas que permitan a los funcionarios el manejo de información veraz en tiempo real.</li>
  <li class="list-group-item" style={{fontWeight:"normal"}}>7.	Capacitación y adiestramiento de los funcionarios en áreas de su competencia.</li>
  <li class="list-group-item" style={{fontWeight:"normal"}}>8.	Actualización y difusión de información en materia de ética, valores, prevención de legitimación de capitales y financiamiento del terrorismo, hacia todos los trabajadores de la Institución.</li>
  <li class="list-group-item" style={{fontWeight:"normal"}}>9.	Cumplimiento con el otorgamiento oportuno de beneficios socio económicos de los trabajadores.</li>
  <li class="list-group-item" style={{fontWeight:"normal"}}>10.	Mantenimiento y mejora de la estructura física del Fondo.</li>
  <li class="list-group-item" style={{fontWeight:"normal"}}>11.	Realización de actividades dirigidas incentivar interrelación del Fondo con la comunidad.</li>

</ul>
              </div>
            </React.Fragment>
          )}
        />

<button
          className={cx("app__toggle", {
            "app__toggle--active": this.state.isOpen5
          })}
          onClick={() => this.toggle(5)}
        >
          Valores 
        </button>

        <Collapse
          isOpen={this.state.isOpen5}
          className={
            "app__collapse app__collapse--gradient " +
            (this.state.isOpen2 ? "app__collapse--active" : "")
          }
          transition="height 800ms cubic-bezier(0.4, 0, 0.2, 1)"
          aria-hidden={this.state.isOpen2 ? "false" : "true"}
          elementType="article"
          render={collapseState => (
            <React.Fragment>
              <div className="app__content">
              <div>
  <MDBRow className="justify-content-center" >

  <div style={{ marginRight:"1rem "}}  >
 
 
 <MDBCard style={{ width: "15rem", marginTop: "2rem" }} id="cart1" className="mx-auto" >

    <MDBView waves ><div style={{ width: "240px",    height:"auto"}} className=" align-items-center"> <img src={uno} className="img-fluid"  alt="img"/></div></MDBView>
  

    
        
   

 </MDBCard>
</div>
<div style={{ marginRight:"1rem "}}  >
 
 
 <MDBCard style={{ width: "15rem", marginTop: "2rem" }} id="cart1" className="mx-auto" >

    <MDBView waves ><div style={{ width: "240px",    height:"auto"}} className=" align-items-center"> <img src={uno1} className="img-fluid"  alt="img"/></div></MDBView>
   

        
   

 </MDBCard>
</div>

<div style={{ marginRight:"1rem "}}  >
 
 
 <MDBCard style={{ width: "15rem", marginTop: "2rem" }} id="cart1" className="mx-auto" >

    <MDBView waves ><div style={{ width: "240px",   height:"auto"}} className=" align-items-center"> <img src={uno2} className="img-fluid"  alt="img"/></div></MDBView>
    

    
        
   

 </MDBCard>
</div>

<div style={{ marginRight:"1rem "}}  >
 
 
 <MDBCard style={{ width: "15rem", marginTop: "2rem" }} id="cart1" className="mx-auto" >

    <MDBView waves ><div style={{ width: "240px",    height:"auto"}} className=" align-items-center"> <img src={uno3} className="img-fluid"  alt="img"/></div></MDBView>
    

    
    
        
   

 </MDBCard>
</div>
<div style={{ marginRight:"1rem "}}  >
 
 
 <MDBCard style={{ width: "15rem", marginTop: "2rem" }} id="cart1" className="mx-auto" >

    <MDBView waves ><div style={{ width: "240px",    height:"auto"}} className=" align-items-center"> <img src={Exelencia} className="img-fluid"  alt="img"/></div></MDBView>
    

        
   

 </MDBCard>
</div>



<div style={{ marginRight:"1rem "}}  >
 
 
 <MDBCard style={{ width: "15rem", marginTop: "2rem" }} id="cart1" className="mx-auto" >

    <MDBView waves ><div style={{ width: "240px",     height:"auto"}} className=" align-items-center"> <img src={Pueblo} className="img-fluid"  alt="img"/></div></MDBView>
    

    
        
   

 </MDBCard>
</div>



<div style={{ marginRight:"1rem "}}  >
 
 
 <MDBCard style={{ width: "15rem", marginTop: "2rem" }} id="cart1" className="mx-auto" >

    <MDBView waves ><div style={{ width: "240px",     height:"auto"}} className=" align-items-center"> <img src={Vocacion} className="img-fluid"  alt="img"/></div></MDBView>
    

    
        
   

 </MDBCard>
</div>










</MDBRow>
</div>
                
               
              </div>
            </React.Fragment>
          )}
        />
 

<button 
          className={cx("app__toggle", {
            "app__toggle--active": this.state.isOpen6
          })}
          onClick={() => this.toggle(6)}
        >Organigrama
          
        </button>

        <Collapse
          isOpen={this.state.isOpen6}
          className={
            "app__collapse app__collapse--gradient " +
            (this.state.isOpen2 ? "app__collapse--active" : "")
          }
          transition="height 800ms cubic-bezier(0.4, 0, 0.2, 1)"
          aria-hidden={this.state.isOpen2 ? "false" : "true"}
          elementType="article"
          render={collapseState => (
            <React.Fragment>
              <div className="app__content">
              
              <img style={{width:"100%"}} src={Organigrama} alt="Organigrama" />
              <blockquote className="d-flex justify-content-center h6 text-white color mx-auto"  style={{ width: "90%", marginTop: "1rem" }}
 >
              Código de Ética y Conducta de los Funcionarios, Obreros y Personal Contratado del Fondo, publicado en la Gaceta Oficial de la República Bolivariana de Venezuela No. 40.343 del 28-01-2014.

              </blockquote>
              </div>
            </React.Fragment>
          )}
        />

       
      </div>
      </>
    );
  }

  toggle = index => {
    let collapse = "isOpen" + index;

    this.setState(prevState => ({ [collapse]: !prevState[collapse] }));
  };
}

export default App;
