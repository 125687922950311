import React, { Component } from 'react';
import "../../index.css"


class NotFound extends Component {
render() {
    return (    
    <div className="gif">
        <img style={{height:600, width: "100%"}} 
        src={require('../../Media/404.gif')} 
        alt="GIF"/>
    </div>
    );
    }
}
export default NotFound;