import React, { Component } from "react";
import { MDBCard, MDBCardImage } from 'mdbreact';
import{Navbar,Nav} from 'react-bootstrap';
import Logo from "../../Media/FOGADE.png"
import Social from "../Social"

import axios from "axios";

const baseUrl = "https://catatumbo.fogade.gob.ve/";

class CardExample extends Component {

  state = {
    entradas: []
  }

  componentDidMount() {
    var postid = document.URL;
    postid = postid.substring(postid.indexOf("ias/") + 4);

  
    axios.get(baseUrl +"Galerias?galeryid=" + postid)
      .then(res => {
        const entradas = res.data;
        this.setState({entradas});
        
      })
  }

  
    render() {
        return (
        <div>
            <Navbar style={{backgroundColor:"#015180"}} expand="lg">
  <img src={Logo}alt="Logo" className="Logo"/>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="ml-auto">
      
      <Nav.Link href="/" className="text-white font-weight-bolder">Inicio</Nav.Link>
      <Nav.Link href="/Nosotros" className="text-white font-weight-bolder">Nosotros</Nav.Link>
      <Nav.Link href="/Convocatoria" className="text-white font-weight-bolder">Convocatoria</Nav.Link>
          </Nav> 
   
     <Social/>
    
  </Navbar.Collapse>
</Navbar>
        <div className="container">
    
         
        <div className="row">

        {this.state.entradas.map(entradas=>(
        <MDBCard style={{ width: "22rem" ,margin:"1px"}}>
        <MDBCardImage className="img-fluid" src={entradas.galeria} />
        
      </MDBCard>))}
      
    </div>
    </div>
    </div>
 )
}
}


export default CardExample;