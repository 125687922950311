import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import Logo from "../Media/FOGADE.png"


import { BrowserRouter as Router } from 'react-router-dom';
import Rutas from "./Rutas"
import Header from './Components/01-Header';
import Footer from './Components/11-Footer';

import Image from './Components/image';





class NavbarPage extends Component {
  render() {
    return (
      <Router>
      <Header/>
      
   

        <Rutas />
        <Image/>
        <Footer/>

        
      </Router>
    );
  }
}

export default NavbarPage;