import React, { Component } from "react";
import InstaGrid from "./Instagram"
import {Tabs,Tab } from 'react-bootstrap';
import axios from "axios"
import Mastodon from './mastodon';
const baseUrl = "https://catatumbo.fogade.gob.ve/";




//import "../Css/lastnotices.css";
//import Instagram from "../Component/Instagram"
//mport Notilast from "../Component/Notilast"




class CardExample extends Component {
  state = {
    entradas: []
  }
  
  componentDidMount() {
    axios.get(baseUrl +'NoticiasAnt?categoria_id=30')
      .then(res => {
        const entradas = res.data;
        this.setState({ entradas});
    })
  }


   

  render() {
    return (   <div className="container">
      <div className="row justify-content-center">
      <div className="col-sm-4 col-md-auto  col-lg-6 col-xl-6">
<div className="thumbnail">
<div className="linea " > 
          <div className="text-center" >
            <strong className="Textolinea" >Noticias Anteriores </strong>
          </div>
        </div>
        <div>
        <div className="row justify-content-center">     
          {this.state.entradas.map(entradas=>
            <div className="lastnotice" >               
              <a href={"/Noticia/"+entradas.postid}>
                <img src={entradas.imagenanterior} alt="Lights" style={{width:"100%",borderRadius:"20px",height:"8rem"}}/>
              </a>
              
              <div  style={{fontSize:"small"}}><a href={"/Noticia/"+entradas.postid} dangerouslySetInnerHTML={{__html: `${entradas.titulo}` }}></a></div>                            
            </div>
          )}
        </div>
        </div>



</div>
</div>
<div className="col-sm-4 col-md-6  col-lg-6 col-xl-6">
<div className="linea"> <div className="text-center"><strong className="Textolinea" >@fogade</strong></div></div>

<Tabs id="controlled-tab-example"    
    >
      
      <Tab eventKey="profile" title="Twitter">
      <a className="twitter-timeline" data-lang="es" data-width="100%" data-height="500" data-theme="dark" href="https://twitter.com/FogadeVE?ref_src=twsrc%5Etfw">Tweets by FogadeVE</a>
      </Tab>
      <Tab eventKey="home" title="Instagram">
      <InstaGrid account="fogade" numberOfMediaElements={9} />
       
      </Tab>
      <Tab eventKey="contact" title="Mastodon" disabled>
        <Mastodon/>
      </Tab>
      </Tabs>

</div>
</div>
</div>


   );
}

}

export default  CardExample;
