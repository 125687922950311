import React,{Component} from "react"
import Carousel from 'react-bootstrap/Carousel'
import 'xerxes-react-carousel/lib/index.css'
import axios from "axios"
const baseUrl = "https://catatumbo.fogade.gob.ve/";




class carrosel extends Component{

    state = {
        entradas: []
      }
      
      componentDidMount() {

         axios.get(baseUrl +"NoticiasGet?categoria_id=30&ignore=false")
          .then(res => {
            const entradas = res.data;
            this.setState({ entradas});
          })
      }
       
    

    render()


    {
      
      return(<div className="maxh">
         
          <Carousel slide={false} >

            {this.state.entradas.map((entradas,index)=> (
              <Carousel.Item key={index} >

           <a href={entradas.urlpdf}>
            <img
              className="h-25 d-inline-block"
              src={entradas.imagenanterior}
              style={{width:"100%",maxHeight:"450px"}}
              alt="First slide"
            />
            </a>
            
            
          </Carousel.Item>
           )
            )
              
          }
           
            
          </Carousel>
        </div>);
    }
}



export default carrosel;