import React, { Component  } from 'react'
 
import BackgroundSlider from 'react-background-slider';
import image1 from "../Media/assets.png"
import "../index.css";


export default class App extends Component {
  
  
  render () {
   
    return (
      <div className="image">
        <BackgroundSlider images={[ image1,image1]} 
        duration={90000} transition={1} 
        />
      </div>
    )
  }
}
