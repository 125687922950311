import React from "react";
import { Component } from "react";
import "../index.css"
import Social from "./Social"


class Footer extends Component{
    render(){
        return(<footer className="copyright">
        	<div className="footer-top">
	        </div>
	        <div className="footer-bottom">
	        	<div className="container">
	        		<div className="row justify-content-center">
	           			<div className="footer justify-content-center ">
						  
	                    	<p className="text-center text-white font-weight-bolder">Rif: G200032413, Edificio <div className="font"><h1 className="font">FOGADE</h1>,</div> Esquina San Jacinto Caracas, Venezuela.<br/>
FONDO DE PROTECCIÓN SOCIAL DE LOS DEPÓSITOS BANCARIOS - Copyright © 2020</p>
<Social  />     

 </div>
						
	                    
	           		</div>
					   
	        	</div>
	        </div>
        </footer>

        );
    }
}


export default Footer ;