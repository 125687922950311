import React,{Component} from "react"

import banner from "../Media/banner.png"


class Header extends Component{
    render(){
        return(<header>
        <img src={banner} style={{width:"100%"}} alt="banner"/>
            </header>
                );}
}

    

export default Header;