import React, { Component } from "react";
import axios from "axios";
import{Navbar,Nav} from 'react-bootstrap';
import Logo from "../../Media/FOGADE.png"
import Social from "../Social"



const baseUrl = "https://catatumbo.fogade.gob.ve/";

class Articulo extends Component {
  state = {
    entradas: []
  }

  componentDidMount() {
    var postid = document.URL;
    postid = postid.substring(postid.indexOf("cia/") + 4);

    axios.get(baseUrl +"NoticiaContent?postid=" + postid)
      .then(res => {
        const entradas = res.data;
        this.setState({entradas});
      })
  }

  render() {
    return (<div>
    <Navbar style={{backgroundColor:"#015180"}} expand="lg">
  <img src={Logo}alt="Logo" className="Logo"/>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="ml-auto">
      
      <Nav.Link href="/" className="text-white font-weight-bolder">Inicio</Nav.Link>
      <Nav.Link href="/Nosotros" className="text-white font-weight-bolder">Nosotros</Nav.Link>
      <Nav.Link href="/Convocatoria" className="text-white font-weight-bolder">Convocatoria</Nav.Link>
          </Nav> 
   
     <Social/>
    
  </Navbar.Collapse>
</Navbar>

      <div style={{margin:"4rem"}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {this.state.entradas.map(entradas=>
                <React.Fragment>
                  <h1 dangerouslySetInnerHTML={{__html: `${entradas.titulo}` }}></h1>
                  <hr/>
                  <img src={entradas.imagenanterior} className="img-responsive" style={{height:"300px",width:"100%"}}alt={entradas.id}/>
                  <hr/>
                  <p style={{textAlign:"justify",fontWeight:"normal"}} dangerouslySetInnerHTML={{__html: `${entradas.contenido}` }}></p>
                  <hr/>
                </React.Fragment>
              )}
            </div>

            <div className="col-lg-4">
              <div className="Lastnotice" >

                
                
                </div>  
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }
}

export default Articulo;