import React, { Component } from "react";

import img001  from "../Media/economicos1.png" 
import img002  from "../Media/economicos2.png" 
import img003  from "../Media/economicos3.png" 
import img004  from "../Media/economicos4.png" 


class Economia extends Component{
    render(){
        return( 
        <div  style={{marginBottom:"2rem" }}>
                  <div  id="Economia" className="linea container">  <div className="text-center"><strong className="Textolinea" >Economia Y Finanza</strong></div></div>

           
                  <div className="row justify-content-center" >
                  <div className="card m-4" style={{marginTop: "20rem"}}>
        <img className="card-img-top" src={img003} style={{ width: "auto",height:"250px"}} alt="COSTO DEL BARRIL"/>
        <div className="card-body">        
   
    <div className="d-flex justify-content-center align-items-center" style={{marginTop:"-45px"}}>
9.947 MM US$ </div>
          </div>
    </div>

    <div className="card m-4" style={{marginTop: "20rem"}} >
        <img className="card-img-top" src={img004} href="https://www.bcv.org.ve/minerales-estrategicos/precio-del-oro#" style={{ width: "auto",    height:"250px"}} alt="COSTO DEL BARRIL" />
        <div className="card-body">
        

<div className="d-flex justify-content-center align-items-center" style={{marginTop:"-45px"}}>
1.817,47$ X(oz) 
</div>
          </div>
    </div>

    <div className="card m-4"style={{marginTop: "20rem"}}>
        <img className="card-img-top" src={img001} href="http://oilprice.com" style={{ width: "auto",    height:"250px"}} alt="AVANCE ECONOMICO"/>
        <div className="card-body">
        
     
     <div className="d-flex justify-content-center align-items-center" style={{marginTop:"-45px"}}>
     537,36 &#165;
    </div>
          </div>
    </div>

    <div className="card m-4" style={{marginTop: "20rem"}}>
        <img className="card-img-top" src={img002} href="https://www.petro.gob.ve/es/" style={{ width: "auto",    height:"250px"}} alt="Oro"/>
        <div className="card-body">          
        
     
     <div className="d-flex justify-content-center align-items-center" style={{marginTop:"-45px"}}>
     1.128,17 Bs
    </div>
          </div>
    </div>
    
   
    </div>
    
    
   
   
</div>

        

 
        
            );
        }
    }
 export default Economia;
