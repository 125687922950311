
import React, { Component } from 'react';
import Carousel from 'xerxes-react-carousel'
import '../index.css'


import axios from "axios"
const baseUrl = "https://catatumbo.fogade.gob.ve/";

class Carousel2 extends Component {

  
  state = {
    entrada: []
  }
  
  componentDidMount() {
    axios.get(baseUrl+'NoticiasGet?categoria_id=30&ignore=true')
      .then(res => {
        const entrada= res.data;
        this.setState({ entrada});
      })
  }

  render() {
    return (
      <div style={{marginTop:"4rem",marginBottom:"4rem"}}> 
        <div className="linea container">  
          <div className="text-center">
            <strong className="Textolinea" id="Noticias" >Noticias </strong>
          </div>
        </div>
        <Carousel type="card" autoplay  interval="1800" height="450">
          {this.state.entrada.map((entrada,index) =>(<Carousel.Item key={index}>   
              <div style={{ height: '100%', width: '100%'}}><a href={"/Noticia/"+entrada.postid}>
                <img style={{ width: '100%', height: '100%' ,borderRadius:"5px 5px 5px 5px" }}  className="image-fluid" src={entrada.imagenanterior} alt={index} />
                <div  className="line1">  
                <div className="shadow">
          <div className="text-center container" style={{color:"white"}} dangerouslySetInnerHTML={{__html: `${entrada.titulo}` }}>
           </div>
           </div>
        </div>
                
          </a></div>   
            </Carousel.Item>
            )
            
          )}
        </Carousel>
      </div>
    );
  }
}

export default Carousel2;