import React from "react";
import { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import{Button} from 'react-bootstrap';
import "../index.css"
import ser1 from "../Media/SERVICIOS1.png"
import ser2 from "../Media/SERVICIOS2.png"
import ser3 from "../Media/SERVICIOS3.png"

 class cardService extends Component {
     render(){
        return(<>
            
            <div className="container align-content-center">
        <div className="row justify-content-center">
        <div  id="Servicios" className="linea container"><div className="text-center"><strong className="Textolinea" >Servicios </strong></div></div>
        <div className="card m-4" style={{marginTop: "20rem",  width: '18rem' }}>
                  <img className="card-img-top" src={ser1}style={{ width: "auto",height:"200px"}} alt="APORTES EN LINEA"/>
                    <div className="card-body">
                  <div className="text-center h5">APORTES EN LINEA</div>
                        <p className="card-text h6">
                        Dirigido únicamente a las instituciones del sector bancario que de acuerdo a lo establecido en el Decreto con Rango, Valor y Fuerza de Ley de Instituciones del Sector Bancario, les corresponde pagar los aportes que deben enterar al Fondo de Protección Social de los Depósitos Bancarios.</p>
                        <div className="text-center"><Button  className="button"><a href="http://sisaportes.fogade.gob.ve/"  target="_blank" className="text-white">Ingrese Aqui</a></Button>

</div>
          </div>
    </div>



    <div className="card m-4" style={{marginTop: "20rem",  width: '18rem' }}>
                  <img className="card-img-top" src={ser2} style={{ width: "auto",height:"200px"}} alt="SIGESEL"/>
                    <div className="card-body">
                  <div className="text-center h5">SIGESEL</div>
                        <p className="card-text h6">
                                          Dirigido al público en general relacionado con los Bancos en Proceso de Liquidación y sus Empresas Relacionadas, así como, aquellas personas naturales o jurídicas que hayan contratado o prestado servicio a Fondo de Protección Social de los Depósitos Bancarios
                                                </p>
                                                <div className="text-center"><Button  className="button"><a href="#"  target="_blank" className="text-white">Ingrese Aqui</a></Button>

</div>

          </div>
    </div>


    <div className="card m-4" style={{marginTop: "20rem",  width: '18rem' }}>
                  <img className="card-img-top" src={ser3} style={{ width: "auto",height:"200px"}} alt="ATENCION AL CIUDADANO"/>
                    <div className="card-body">
                  <div className="text-center h5">ATENCION AL CIUDADANO</div>
                            <p className="card-text h6">
                            Gestión de solicitudes relacionadas con los Bancos en Proceso de Liquidación y sus Empresas Relacionadas, así como, atención al personal activo y jubilado de Fondo de Protección Social de los Depósitos Bancarios.</p>
    
                            <div className="text-center"><Button  className="button"><a href="#"  target="_blank" className="text-white">Ingrese Aqui</a></Button>

</div>

          </div>
    </div>




</div>
</div>




    
   
   




 </>)
     }
 }
export default cardService;
