import React, { Component } from "react"
import Twitter from "./../Media/twitter.svg"
import Instagram from "./../Media/instagram.svg"



class social1 extends Component {
render(){
    return(<div className=" row justify-content-center" >
   <a href="https://twitter.com/FogadeVE">
   <img src={Twitter} className="logosocial" alt="..."/>
   </a>
   <a href="https://www.instagram.com/fogade/">
   <img src={Instagram} className="logosocial" alt="..."/>
   </a>



    </div>
     
    );
    }
}
export default social1;
