import React from 'react';
import ReactDOM from 'react-dom';
import "react-bootstrap"
import Inicio from "./Fogade"
import './index.css';


ReactDOM.render(<Inicio/>, document.getElementById('root'));


