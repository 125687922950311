import React, { Component } from 'react'
import axios from 'axios'


class Mastodon extends Component{
    state = {
        entradas: []
      }
      
      componentDidMount() {
        axios.get('https://mastodon.social/api/v1/accounts/1170417/statuses')
          .then(res => {
            const entradas = res.data;
            this.setState({ entradas});
        })
      }
    render(){
        return(
           <div style={{overflowY:"scroll",height:"450px"}}>
           
         
             

             </div>


       );
      }
    }


export default Mastodon;