import React, { Component } from 'react';
import fo1 from "../Media/enlaces9.svg"
import fo2 from "../Media/enlaces1.svg"
import fo3 from "../Media/enlaces2.svg"
import fo4 from "../Media/enlaces3.svg"
import fo5 from "../Media/enlaces4.svg"
import fo6 from "../Media/enlaces5.svg"

import fo8 from "../Media/enlaces7.svg"
import fo9 from "../Media/enlaces8.svg"
import './Styles/Enlaces.scss';
import "../index.css"





class Enlace extends Component {
    render() {
      return (       
        <div  className="text-center img-fluid">
        <div className="linea container">  <div className="text-center"><strong className="Textolinea" >Enlaces</strong></div></div>
            
        <div id="slider">
	<div id="slide-track">
	   <a href="http://www.bcv.org.ve/"id="slide">			
			<img src={fo1} height="100" width="250" alt="bcv" />
		</a>
		<a href="http://www.mppef.gob.ve/"id="slide">
			<img src={fo2} height="100" width="250" alt="mppef" />
		</a>
		<a href="https://www.bandes.gob.ve/"id="slide">
			<img src={fo3} height="100" width="250" alt="bandes" />
		</a>
		<a href="http://bancodevenezuela.com/"id="slide">
			<img src={fo4} height="100" width="250" alt="bancodevenezuela" />
		</a>
    <a href="http://www.bt.gob.ve/" id="slide">
			<img src={fo5} height="100" width="250" alt="bt" />
		</a>
    <a href="http://www.bicentenariobu.com/" id="slide">
			<img src={fo6} height="100" width="250" alt="bicentenariobu" />
		</a>
    <a href="http://www.sudeban.gob.ve/"id="slide">
			<img src={fo8} height="100" width="250" alt="sudeban" />
		</a>
    <a href="http://www.psuv.org.ve/" id="slide">
			<img src={fo9} height="100" width="250" alt="pvsu" />
		</a>
		
    
		
	</div>
</div>




        </div>
      );
    }
  }
  
  export default Enlace;