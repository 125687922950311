import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Inicio from "./Components/Pages/Inicio"
import Nosotros from "./Components/Pages/Nosotros"
import Articulo from './Components/Pages/Articulo';
import Convocatoria from './Components/Pages/Convocatoria'
import Galeria from "./Components/Pages/Galeria"
 import NotFound from './Components/Pages/NotFound'

class Routes extends React.Component {
        render() { 
            return (
        <Switch>
                <Route exact path='/' component={Inicio} />
                <Route  exact path='/Nosotros' component={Nosotros} />
               
                <Route  exact path="/Noticia/:post" component={Articulo} />
                <Route  exact path='/Convocatoria' component={Convocatoria} />
                <Route exact path='/Galerias/:post' component={Galeria}/> 
                <Route render={function() {
                        return <div><NotFound/></div>;
            }} 
        />
      </Switch>
    );
  }
}

export default Routes;
